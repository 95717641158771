<!-- System: STA
    Purpose: This compoment will display Members of
                Team Lead, where user can remove member
                etc.
-->
<template>
  <v-app>
    <div class="row d-flex flex-column justify-center align-items-center">
      <!-- All Team Lead Card -->
      <v-card
        width="700"
        class="d-flex flex-column justify-center mt-12"
        elevation="10"
      >
        <h3 class="justify-center heading center my-6">All Team Leads</h3>
        <div>
          <!-- Table to represent all team leads and their members -->
          <v-data-table
            id="leads-table"
            class="w-20"
            :headers="headers"
            :items="teamLeads"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.image="props">
              <v-tooltip class="uner_info_tooltip" top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    width="50px"
                    :src="$api_url + 'storage/' + checkUserImage(props.item.id)"
                    @error="
                      $event.target.src = require('../assets/images/no-member.svg')
                    "
                    alt="Thumbnail"
                    class="timesheet-image"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span
                  ><UserInfo :member="userDetail(props.item.id)"></UserInfo
                ></span>
              </v-tooltip>
            </template>
            <template v-slot:item.name="props">
              <span class="w-10">{{ props.item.name }}</span>
            </template>
            <template v-slot:item.id="props">
              <!-- Get all team members -->
              <v-dialog v-model="dialog" scrollable max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="getMembers(props.item.id)"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        class="ml-1 icon-color"
                        data-trigger="edit_profile_member"
                        size="23"
                      >
                        mdi-account-group
                      </v-icon>
                    </template>
                    <span>Team lead members</span>
                  </v-tooltip>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
          <!-- Start : Team leads all team members show -->
          <div class="text-center">
            <v-dialog v-model="teamMemberShow" width="800">
              <v-card>
                <v-card-title class="justify-center"
                  >Team Lead Members</v-card-title
                >
                <v-divider></v-divider>
                <v-btn
                  color="error"
                  class="ml-11 my-3 d-block text-transform"
                  @click="removeMember('all', true)"
                  small
                >
                  Remove all Members
                </v-btn>
                <v-card-text>
                  <v-container class="grey lighten-5 team-cont">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        class="pointer pa-1"
                        v-for="member in teamMembers"
                        :key="member.hash_id"
                        @click="removeMember(member.hash_id, false)"
                      >
                        <v-card class="padding-card pa-2" outlined tile>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  height: 35px;
                                  background-color: rgb(
                                    74,
                                    207,
                                    139
                                  ) !important;
                                "
                                class="mt-3 name"
                              >
                                {{ employeeName(member.name) }}
                              </div>
                            </template>
                            <span>{{ member.name }}</span>
                          </v-tooltip>
                          <v-divider></v-divider>
                          <div class="mx-auto">
                            <img
                              :src="$api_url + 'storage/' + member.image"
                              @error="
                                $event.target.src = require('../assets/images/no-member.svg')
                              "
                              alt="Thumbnail"
                              class="member-image my-3"
                              fluid
                              thumbnail
                            />
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <!-- End : Team leads all team members show -->
        </div>
      </v-card>
      <v-dialog v-model="remove_member_dialog" max-width="490">
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="text-justify pa-4">
            You want to remove member
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              text
              @click="remove_member_dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="error" outlined text @click.prevent="removeTLMember">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-app>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import _ from "lodash";
import UserInfo from "../components/includes/UserInfo";
import Snackbar from "@/components/includes/Snackbar";

export default {
  name: "AllTeamLeads",
  data() {
    return {
      snackbar: false,
      snackbarColor: "",
      message: "",
      remove_member_dialog: false,
      member_id: "",
      remove_all: "",
      dialog: false,
      headers: [
        { text: "", align: "center", sortable: false, value: "image" },
        { text: "Team Lead", align: "start", sortable: true, value: "name" },
        { text: "Action", align: "center", sortable: false, value: "id" },
      ],
      teamMemberShow: false,
      teamMembers: null,
      leaderId: null,
    };
  },
  components: {
    UserInfo,
    Snackbar,
  },
  computed: {
    ...mapState("custom", ["active_team_members_list"]),
    //  Function to compute all team leads of the selected company
    teamLeads() {
      let allTeamLeads;
      allTeamLeads = this.active_team_members_list.filter(
        (member) => member.team_lead_members == "TEAMLEAD"
      );
      allTeamLeads = _.orderBy(allTeamLeads, ["name"], ["asc"]);
      return allTeamLeads;
    },
  },
  mounted() {
    const company = this.$store.state.custom.companies_list.find(
      (c) => c.id === this.$route.params.id
    );
    //   Redirect user to dashboard page in case of employee using all team leads route
    if (company) {
      if (company.user_status == "employee") {
        this.$router.push({ name: "dashboard" });
      }
    } else {
      this.$router.push({ name: "dashboard" });
    }
    if (this.$store.state.custom.active_team_members_list.length === 0) {
      this.get_team_members_list();
    }
  },
  methods: {
    checkUserImage(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      member = member ? member.image : null;
      return member;
    },
    userDetail(id) {
      return this.$store.state.custom.get_team_members_list.find(
        (data) => data.id === id
      );
    },
    /**
     * This function is resposible for
     * Fetching the active team member list
     */
    get_team_members_list() {
      let company_id = this.$route.params.id;
      let search_data = {
        project: null,
      };
      this.$store.dispatch("custom/get_active_team_members_list", {
        company_id,
        search_data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },

    url() {
      return baseURL.API_URL;
    },
    /**
     * This function is resposible for
     * Employee name validations
     */
    employeeName(name) {
      return name
        ? name.length > 13
          ? name.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g) !== null
            ? name
            : name
                .match(/\b(\w)/g)
                .join(" ")
                .toUpperCase()
                .slice(0, -1)
                .concat(" " + name.split(" ").slice(-1))
          : name
        : "(No name set yet)";
    },
    /**
     * This function is resposible for
     * removing members from teamlead
     */
    removeTLMember() {
      let data = {
        company_id: this.$route.params.id,
        leader_id: this.leaderId,
        members: this.member_id,
        remove_all: this.remove_all,
      };
      this.remove_member_dialog = false;
      this.$store
        .dispatch("custom/updateTeamMemberforLeader", data)
        .then((response) => {
          if (this.remove_all) {
            this.teamMembers = [];
          }
          this.remove_member_dialog = false;
          this.$store.commit("custom/toggle_loader", false);
          this.teamMembers = this.teamMembers.filter(
            (team) => team.hash_id != this.member_id
          );
          this.message = response.data.error_msg;
          this.snackbarColor = "green";
          this.snackbar = true;
          if (this.remove_all || this.teamMembers.length == 0) {
            this.teamMemberShow = false;
            this.remove_member_dialog = false;
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * Function to remove member from a team
     */
    removeMember(member_id, removeAll) {
      this.remove_member_dialog = true;
      this.member_id = member_id;
      this.remove_all = removeAll;
    },
    /**
     * This function is resposible for
     * Get all members of a team lead
     */
    getMembers(memberid) {
      this.$store.commit("custom/toggle_loader", true);
      this.teamMembers = null;
      this.leaderId = memberid;
      this.teamMemberShow = true;
      let data = {
        company_id: this.$route.params.id,
        member_id: memberid,
      };
      this.$store
        .dispatch("custom/getTeamLeadMembers", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.teamMembers = response.data.team_members;
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
  },
};
</script>

<style scoped>
pointer {
  cursor: pointer;
}
.heading {
  color: #2758f6;
}
.name {
  color: #36454f;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}
.w-20 {
  width: 500px !important;
}
.w-10 {
  width: 100px !important;
}
.justify-center {
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}
.transparent {
  background-color: transparent !important;
}
img.member-image {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
  border-radius: 50%;
  width: 86px;
  height: 84px;
}
.container.grey.lighten-5.team-cont {
  background-color: white !important;
}
.padding-card.pa-2.v-card.v-sheet.v-sheet--outlined.theme--light.rounded-0 {
  padding: 0px !important;
}
span.name {
  margin-top: -14px;
  font-size: 15px;
}
.text-transform {
  text-transform: none !important;
}
.timesheet-image {
  width: 31px;
  border-radius: 100%;
  max-width: 65px;
  max-height: 65px;
  border: none;
  margin-left: -2px !important;
}
</style>
